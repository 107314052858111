import React, {useState} from 'react';
import left from '../../assets/img/left.png';
import right from '../../assets/img/right.png';
import leftinactive from '../../assets/img/leftdisabled.png';
import rightinactive from '../../assets/img/rightdisabled.png';
import videopreview from '../../assets/img/videopreview.png';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';

const items = [
    {
        url: '',
        quote: 'Para QualityCode no existe el no se puede, sino lo contrario, podemos hacerlo de esta forma. Nos brinda tranquilidad dejar en manos de profesionales los sistemas de Casanova ',
        author: 'Marcela Fernández',
        position: 'Directora de Marketing · Casanova'
    },
    {
        url: '',
        quote: 'Para QualityCode no existe el no se puede, sino lo contrario, podemos hacerlo de esta forma. Nos brinda tranquilidad dejar en manos de profesionales los sistemas de Casanova ',
        author: 'Marcela Fernández',
        position: 'Directora de Marketing · Casanova'
    },
    {
        url: '',
        quote: 'Para QualityCode no existe el no se puede, sino lo contrario, podemos hacerlo de esta forma. Nos brinda tranquilidad dejar en manos de profesionales los sistemas de Casanova ',
        author: 'Marcela Fernández',
        position: 'Directora de Marketing · Casanova'
    }
]

export default function Experiences(){
    
    const [currentItem, setCurrentItem] = useState(0);

    const {t} = useTranslation('Experiences');

    const next = () => {
        if(currentItem + 1 <= (items.length - 1)){
            setCurrentItem(currentItem + 1);
        }
    }

    const prev = () => {
        if(currentItem - 1 >= 0){
            setCurrentItem(currentItem - 1)
        }
    }

    const ExperiencesBlurb = ({url, quote, author, position, index}) => 
        <div className="Experiences__blurb">
            <p className="Experiences__blurb__quote">
                <Trans t={t} i18nKey={`quote${index+1}`}>“{quote}” </Trans>
            </p>
            <p className="Experiences__blurb__author">
                <span className="Experiences__blurb__author__name">{author}</span><br />
                <span><Trans t={t} i18nKey={`position${index+1}`}>{position}</Trans></span>
            </p>
        </div>
    
    return (
        <section className="Experiences container cols">
            <div className="col">
                <div className="Experiences__video">
                    <img width="568" height="456" src={videopreview} alt="video"/>
                </div>
            </div>
            <div className="col">
                <h2 className="title--1"><Trans t={t} i18nKey="title">Permítenos ayudarte al igual que ellos_</Trans></h2>
                <div className="Experiences__blurb__visible">
                    <div style={{left: `${currentItem * -100}%`}} className="Experiences__blurb__container">
                        {items.map((item, i)=> <ExperiencesBlurb key={i} {...item} index={i} />)}
                    </div>
                </div>
                <div className="Experiences__controls">
                    <button onClick={prev} type="button" className={currentItem === 0 ? 'left__inactive' : 'left'}>
                        <img width="76" height="22" src={currentItem === 0 ? leftinactive : left} alt="Atrás" />
                    </button>
                    <button onClick={next} type="button" className={currentItem === items.length-1 ? 'right__inactive' : 'right'}>
                        <img width="76" height="22"  src={currentItem === items.length-1 ? rightinactive : right} alt="Adelante" />
                    </button>
                </div>
            </div>
        </section>
    )
}