import * as Yup from 'yup';
import { setHours, setMinutes, addDays } from 'date-fns';

const ContactFormValidations = (t) => Yup.object().shape({
    name: Yup.string()
     .min(2, t('nameFormat',{defaultValue:  'Escribe un nombre válido'}))
     .required(t('required',{defaultValue:  'Requerido'})),
    email: Yup.string().email(t('emailFormat',{defaultValue:  'Escribe un correo válido'})).required(t('required',{defaultValue:  'Requerido'})),
    date: Yup.date()
        .required(t('required',{defaultValue:  'Requerido'}))
        .min(setHours(setMinutes(new Date(), 0), 9), t('validDate',{defaultValue:  'Selecciona una fecha y hora válidas para tu cita'}))
        .max(setHours(setMinutes(addDays(new Date(), 30), 0), 18), t('validDate',{defaultValue:  'Selecciona una fecha y hora válidas para tu cita'}))
})

export default ContactFormValidations;