import React, { forwardRef, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import  DatePicker, { registerLocale}  from 'react-datepicker';
import { format, setHours, setMinutes, addDays } from 'date-fns';
import es from "date-fns/locale/es"
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import validations from './validations';
import { init, send } from 'emailjs-com';

init("user_Vom2pRY7zPjWjzGxEv3AR");
registerLocale("es", es);

export default function ContactFormComponent({buttonColor, formColor}){

    const {t, i18n} = useTranslation('ContactForm');
    const {language} = i18n;
    const [startDate, setStartDate] = useState('');
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const postForm = (values, {resetForm}) => {
        values.date = format(values.date, 'dd/MM/yyyy hh:mm:ss a');
        setFormError(false);
        setFormSuccess(false);
        send("service_z5bj2hb",`${process.env.REACT_APP_EMAIL_TEMPLATE}`, values).then(() => {
            resetForm();
            setStartDate('');
            setFormSuccess(true);
        }).catch(() => {
            setFormError(true);
        });
    } 

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            date: startDate,
        },
        validationSchema: () => validations(t),
        onSubmit: postForm,
    });

    const {validateForm} = formik;
    useEffect(() => {
        validateForm();
    }, [language, validateForm])
    
    const DatePickerButton  = forwardRef(({ value, onClick }, ref) => (
        <div className="ContactForm__form__inputcontainer">
            <button className="ContactForm__form__datepicker" type="button" onClick={onClick} ref={ref}>
            { value || 'Fecha y hora de cita*'}
            </button>
        </div>
      ));
      console.log(formik.values);

    return(
        
        <div className={`ContactForm__form ${formColor}`}>
            <form id="contactform" onSubmit={formik.handleSubmit}>
                <h3 className="title--2 margin-text">
                <Trans t={t} i18nKey="subtitle">Agenda una videollamada</Trans></h3>
                <div className="ContactForm__form__inputcontainer">
                    <input 
                        maxLength="50" 
                        name="name" 
                        value={formik.values.name} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur} 
                        placeholder={t('name', {
                            defaultValue:  'Tu nombre*'
                        })} 
                    />
                        {formik.errors.name && formik.touched.name ? <span className="ContactForm__form__inputerror">{formik.errors.name}</span> : null}
                </div>
                <div className="ContactForm__form__inputcontainer">
                    <input 
                        maxLength="100" 
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={t('email',{
                            defaultValue:  'Correo electrónico*'
                        })} 
                    />
                    {formik.errors.email && formik.touched.email ? <span className="ContactForm__form__inputerror">{formik.errors.email}</span> : null}
                </div>
                <div className="ContactForm__form__inputcontainer">
                    <DatePicker
                        selected={startDate}
                        onChange={date =>{console.log(date); setStartDate(date); formik.setFieldValue('date', date, false); formik.setFieldTouched('date', false, false); formik.validateField('date')}} 
                        showTimeSelect
                        wrapperClassName="datepicker" 
                        dateFormat="dd/MM/yyyy hh:mm:ss a"
                        locale="es"
                        timeCaption="Hora"
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 0), 18)}
                        maxDate={addDays(new Date(), 30)}
                        minDate={new Date()}
                        customInput={<DatePickerButton />}
                    />
                    
                    {formik.errors.date && formik.touched.date ? <span className="ContactForm__form__inputerror">{formik.errors.date}</span> : null}
                    {formError ? <span className="ContactForm__form__inputerror"><Trans t={t} i18nKey="formError">Error al enviar, inténtalo de nuevo</Trans></span> : null}
                    {formSuccess ? <span className="ContactForm__form__inputerror"><Trans t={t} i18nKey="formSuccess">¡Muchas gracias por comunicarte por nosotros!</Trans></span> : null}
                </div>
                <button type="submit" className={buttonColor || 'white'}>
                    <Trans t={t} i18nKey="submit">Agendar ahora</Trans>
                </button>
            </form>
            
        </div>
    )
}